import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

interface AboutPageTemplateProps {
    title: string
    content: string
    contentComponent?: React.ComponentType<any>
}

export const AboutPageTemplate: React.FC<AboutPageTemplateProps> = ({ title, content, contentComponent }) => {
    const PageContent = contentComponent || Content

    return (
        <section className="section section--gradient">
            <div className="container">
                <div className="columns">
                    <div className="column is-10">
                        <h2 className="title is-size-3 has-text-weight-bold is-bold-light">{title}</h2>
                        <PageContent className="content" content={content} />
                    </div>
                </div>
            </div>
        </section>
    )
}

interface AboutPageProps {
    data: any
}

const AboutPage: React.FC<AboutPageProps> = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <Layout>
            <AboutPageTemplate contentComponent={HTMLContent} title={post.frontmatter.title} content={post.html} />
        </Layout>
    )
}

AboutPage.propTypes = {
    data: PropTypes.object.isRequired
}

export default AboutPage

export const aboutPageQuery = graphql`
    query AboutPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
            }
        }
    }
`
